@import url(./import.css);

nav {
  height: 50px;
}
.nav-title {
  font-weight: light;
  font-size: 15px;
  color: var(--secound-color);
}
.nav-right {
  color: var(--secound-color);
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
}

@media only screen and (max-width: 990px) {
  .main-nav {
    display: none;
  }
  .main-sec-sub-nav {
    overflow: scroll;
    height: 100px;
  }
}
@media only screen and (min-width: 990px) {
  .off-nav {
    display: none;
  }
}
