.deshborad-btn {
  cursor: pointer;
  background-color: rgb(57, 97, 94);
  padding: 15px;
  border-radius: 5px;
  height: 100px;
  text-align: center;
  width: 100px;
}
.deshborad-btn img {
  width: 50px;
  margin-bottom: 5px;
}
.deshborad-btn p {
  font-weight: 300;
}
