@import url(../CSS/variable.css);

.btn-brand {
  background-color: var(--secound-color);
  color: #fff;
  font-weight: bold;
}

.bg-brand-soft {
  background-color: var(--secound-color-soft);
}

.bg-brand {
  background-color: var(--brand-color);
}
table {
  font-size: 13px;
}
.pointer {
  cursor: pointer;
}
Label {
  color: var(--secound-color);
  font-weight: 400;
  font-size: 14px;
  margin-left: 5px;
}
.title-color {
  color: var(--secound-color);
}
