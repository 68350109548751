@import "./import.css";

.inner-nav-icon a {
  color: var(--secound-color);
  width: 60px;
  border-radius: 50%;
}
.inner-nav-icon div {
  cursor: pointer;
}
