body {
  overflow-x: hidden;
  background-color: #51651e09;
  font-family: "Roboto", sans-serif;
}
input:focus {
  border: 1px solid #51651e;
}
input::placeholder {
  color: rgba(51, 51, 51, 0.541) !important;
}
.d-flex {
  display: flex;
}
.align-items-center {
  align-items: center;
}
