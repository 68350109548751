.point {
  font-size: 13px;
  font-weight: 300;
  margin-bottom: 10px;
}
.point span {
  margin-left: 10px;
  font-weight: 500;
}
.body-title {
  font-size: 14px;
  color: #424242 !important;
  font-weight: 600;
}
.btn-brand:hover {
  color: #fff;
}
