@import "./import.css";

.hero {
  height: 100vh;
}

.btn-brand:hover {
  border: 1px solid var(--secound-color);
  border-radius: 5px;
  background-color: var(--secound-color-overlay);
}
.hero h1 {
  color: var(--secound-color);
}
.hero h3 {
  color: var(--secound-color);
}
