.profile-cover {
  position: relative;
}
.cover-img {
  width: 1100px;
  max-height: 300px;
}
.profile-img {
  position: absolute;
  width: 200px;
  bottom: -80px;
  left: 25px;
}
.bip {
  margin-top: 10px;
  margin-right: 5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #00d134;
}
.redbip {
  margin-top: 10px;
  margin-right: 5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ff0000;
}
.profile-details {
  position: relative;
}
.profile-details p {
  font-size: 13px;
  font-weight: 300;
}
.profile-details-edit {
  position: absolute;
  border: 1px solid black;
  padding: 2px;
  border-radius: 50%;
  background-color: #fff;
  top: -10px;
  right: 20px;
  font-size: 30px;
}
.account-type {
  padding: 5px 10px;
  border: 1px solid #097420;
  border-radius: 5px;
  font-size: 13px;
  display: inline;
  color: #0f0f0f;
}
.profile-details-close {
  position: absolute;
  border-radius: 50%;
  color: red;
  top: -10px;
  right: 60px;
  font-size: 30px;
  font-size: 30px;
}
